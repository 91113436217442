import tw from "twin.macro"

export const Form = tw.form`
  mt-1 md:mt-0
`

export const Content = tw.div`
  pt-4 pb-6-4 flex flex-col items-center justify-center w-full
`

export const Title = tw.h2`
  mb-3-2 md:mb-4-8 text-18 md:text-18 leading-none font-medium px-1-6 text-center
`

export const Description = tw.p`
  text-18 mb-2-4
`
