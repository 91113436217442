import React from "react"

import { withDetailsForm } from "./withDetailsForm"
import { Form, Content, Title, Description } from "./DetailsFormStyles"
import { Input } from "../../../Form/Input"
import { Errors, Error, Submit } from "../../Layout/LayoutStyles"
import { Popup } from "../../../Popup/Popup"
import { StyledButton } from "../../../Styled/Button"

export const DetailsForm = withDetailsForm(
  ({
    customer,
    handleChange,
    handleSubmit,
    password,
    handlePasswordChange,
    saving,
    errors,
    showPasswordChangedPopup,
    handlePasswordChangedPopup,
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalEmailPlaceholder,
    additionalPhoneLabel,
    additionalPhonePlaceholder,
    additionalPasswordPlaceholder,
    additionalPasswordLabel,
    additionalSubmitButton,
    additionalSubmittingButton,
    additionalPasswordChangedTitle,
    additionalPasswordChangedDescription,
    additionalPasswordChangedButtonText,
  }): JSX.Element => (
    <>
      <Form onSubmit={handleSubmit}>
        <Input
          layout={"address"}
          name="firstName"
          type="text"
          value={customer?.firstName}
          onChange={handleChange}
          label={additionalFirstNamePlaceholder}
          spacing={"small"}
        />
        <Input
          layout={"address"}
          name="lastName"
          type="text"
          value={customer?.lastName}
          onChange={handleChange}
          label={additionalLastNamePlaceholder}
          spacing={"small"}
        />
        <Input
          layout={"address"}
          name="email"
          type="email"
          value={customer?.email}
          onChange={handleChange}
          label={additionalEmailPlaceholder}
          required
          readOnly
          spacing={"small"}
        />
        <Input
          layout={"address"}
          name="phone"
          type="text"
          value={customer?.phone}
          onChange={handleChange}
          label={additionalPhoneLabel}
          placeholder={additionalPhonePlaceholder}
          spacing={"small"}
        />
        <Input
          layout={"address"}
          name="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder={additionalPasswordPlaceholder}
          label={additionalPasswordLabel}
          spacing={"small"}
        />
        {errors?.length > 0 && (
          <Errors>
            {errors?.map((error, index) => (
              <Error key={index}>
                <p>{error?.message}</p>
              </Error>
            ))}
          </Errors>
        )}
        <Submit type={`submit`} colour={"yellow"} disabled={saving} wide>
          {!saving ? additionalSubmitButton : additionalSubmittingButton}
        </Submit>
      </Form>
      <Popup active={showPasswordChangedPopup} setActive={handlePasswordChangedPopup} width={"md"}>
        <Content>
          <Title>{additionalPasswordChangedTitle}</Title>
          <Description>{additionalPasswordChangedDescription}</Description>
          <StyledButton colour={"yellow"} onClick={() => handlePasswordChangedPopup(false)}>
            {additionalPasswordChangedButtonText}
          </StyledButton>
        </Content>
      </Popup>
    </>
  )
)
