import React from "react"

import { StyledInput } from "../Styled/Form"
import { Body } from "../Styled/Text"

export const Input = ({ label, className = "", error = false, success = false, spacing = "", required = false, ...props }) => (
  <label className={className} htmlFor={label}>
    <Body spacing={spacing} colour={error ? "red" : success ? "green" : "black"}>
      {required ? `${label}*` : label}
    </Body>
    <StyledInput success={success} error={error} spacing={spacing} required={required} {...props} />
  </label>
)
